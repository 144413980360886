import { graphql } from "gatsby";

import Layout from "../../components/layout-main";
import { Box, Grid } from "theme-ui";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout
      location={location}
      title={siteTitle}
      pageTitle="Remembering Dorothy Ann Phillips"
      hideSubscribe={true}
    >
      <section className="aw-accent">
        <div className="aw-content">
          <Grid columns={["none", "200px 1fr", "200px 1fr"]} sx={{}}>
            <Box sx={{ maxWidth: "200px" }}>
              <img
                width="100%"
                src="/images/people/dorothy-ann-phillips.jpg"
                alt="Dorothy"
              />
            </Box>
            <Box mr={[0, 3, 5]} sx={{}}>
              <h1>Dorothy Ann Phillips</h1>
              <h4>October 30, 1937 - July 29, 2020</h4>
            </Box>
          </Grid>
        </div>
      </section>
      <section>
        <div className="aw-content">
          <h2>Share a Story</h2>
          <iframe
            title="memory-add"
            src="https://stories.afterword.com/fsaob8gw9"
            allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
            width="100%"
            height="600px"
            style={{ border: "none", borderRadius: "24px" }}
          ></iframe>
        </div>
      </section>
      <section
        sx={{
          // backgroundColor: "rgb(240, 240, 240)",
          marginTop: ["0", "20px"],
        }}
      >
        <div className="aw-content">
          <h2>Shared Stories</h2>
          <iframe
            title="memory-book"
            src="https://stories.afterword.com/re8hk3b5ob7kw8v9on37mh27njhnk25mpgcycc5g"
            allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
            width="100%"
            height="600px"
            style={{ border: "none", borderRadius: "24px" }}
          ></iframe>
        </div>
      </section>
    </Layout>
  );
};

export default ThisPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
